import React, { useState } from "react";

const tryoutDay = "August 10th";

const RegionCosts = () => {
  return (
    <>
      <table>
        <thead>
          <tr>
            <td>Due Date</td>
            <td>Monthly Plan</td>
            <td>Pay In Full</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{tryoutDay} (Tryouts)</td>
            <td>$425</td>
            <td>$425</td>
          </tr>
          <tr>
            <td>Sep 1st</td>
            <td>$425</td>
            <td>$1175</td>
          </tr>
          <tr>
            <td>Oct 1st</td>
            <td>$425</td>
            <td>$0</td>
          </tr>
          <tr>
            <td>Nov 1st</td>
            <td>$425</td>
            <td>$0</td>
          </tr>
          <tr className="boldrow">
            <td>Total</td>
            <td>$1700</td>
            <td>$1600</td>
          </tr>
        </tbody>
      </table>
      <p>Region teams do not travel to the SoCal Cup in December.</p>
    </>
  );
};
const TravelCosts = () => {
  return (
    <>
      <table>
        <thead>
          <tr>
            <td>Due Date</td>
            <td>Monthly Plan</td>
            <td>Pay In Full</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{tryoutDay} (Tryouts)</td>
            <td>$475</td>
            <td>$475</td>
          </tr>
          <tr>
            <td>Sep 1st</td>
            <td>$475</td>
            <td>$1325</td>
          </tr>
          <tr>
            <td>Oct 1st</td>
            <td>$475</td>
            <td>$0</td>
          </tr>
          <tr>
            <td>Nov 1st</td>
            <td>$475</td>
            <td>$0</td>
          </tr>
          <tr className="boldrow">
            <td>Total</td>
            <td>$1900</td>
            <td>$1800</td>
          </tr>
        </tbody>
      </table>
      <p>
        Travel and hotels are not covered with dues for travel teams. Travel
        team parents should budget for travel and hotel expenses for the
        December "SoCal Winter Formal" in Los Angeles.
      </p>
    </>
  );
};
const YoungCosts = () => {
  return (
    <>
      {/* <table>
        <thead>
          <tr>
            <td>Due Date</td>
            <td>Monthly Plan</td>
            <td>Pay In Full</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{tryoutDay} (Tryouts)</td>
            <td>$200</td>
            <td>$200</td>
          </tr>
          <tr>
            <td>Sep 1st</td>
            <td>$200</td>
            <td>$500</td>
          </tr>
          <tr>
            <td>Oct 1st</td>
            <td>$200</td>
            <td>$0</td>
          </tr>
          <tr>
            <td>Nov 1st</td>
            <td>$200</td>
            <td>$0</td>
          </tr>
          <tr className="boldrow">
            <td>Total</td>
            <td>$800</td>
            <td>$700</td>
          </tr>
        </tbody>
      </table> */}
      <p>Email us for costs! Director@SidewinderVA.com</p>
      <p>
        12u and 13u teams can also attend the SoCal tournament in December if
        parents agree to it. The total cost would be $200 more per athlete.
      </p>
    </>
  );
};

const Fall = () => {
  const [cost, setCost] = useState({
    name: "Region Teams",
    data: <RegionCosts />,
  });
  const costs = [
    { name: "Region Teams", data: <RegionCosts /> },
    { name: "Travel Teams", data: <TravelCosts /> },
    { name: "13 and under", data: <YoungCosts /> },
  ];
  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <h1>2024 Fall Season</h1>
        <h4>Tryouts: {tryoutDay} @ Tucson High</h4>
        <h5>15u (Freshmen) and younger: 11:00 am - 1:00 pm</h5>
        <h5> 16u (Sophomores): 1:30 pm - 4:00 pm</h5>
        <h5>17u/18u (Juniors/Seniors): 4:30 pm - 7:00 pm </h5>
        <h4>Register online first, show up AT LEAST 15 minutes early</h4>
      </div>
      <h3>What does the Fall season look like?</h3>
      <h5>Early August - End of December</h5>
      <p>
        All teams attend 5 Arizona Region tournaments and the Arizona Region
        championships - all 6 tournaments take place on Saturdays in Phoenix.
        <br />
        <br />
        Our top team in each age group participates in the{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://socalcupvolleyball.com"
        >
          SoCal Winter Formal
        </a>{" "}
        in Los Angeles in December. The cost difference between the two teams
        are at the bottom of this page.
      </p>
      <h3>Tournament Schedule</h3>
      <ul style={{ listStyle: "none" }}>
        <li>AZ Region #1 - September 21st</li>
        <li>AZ Region #2 - September 28th</li>
        <li>AZ Region #3 - October 12th</li>
        <li>AZ Region #4 - October 26th</li>
        <li>AZ Region #5 - November 9th</li>
        <li>AZ Region Championships - December 7th</li>
        <li>SoCal Winter Formal*</li>
      </ul>
      <p>
        *The SoCal winter formal is only attended by our top team in each
        14u-18u age group. 12u and 13u teams may attend some years depending on
        parent input.
      </p>
      <h3>Fall Season Costs</h3>
      <p>
        Tryouts are $25. After making a team, dues will be structured as shown
        below:
      </p>

      <div style={{ textAlign: "center" }}>
        {costs.map((c) => {
          return (
            <button
              className={"coachButton" + (cost.name === c.name ? " sel" : "")}
              onClick={() => setCost(c)}
            >
              {c.name}
            </button>
          );
        })}
      </div>
      {cost.data}
      <h3>Discounts</h3>
      <p>
        Help us build the next generation! Refer an athlete to tryout for the
        younger age groups - for each athlete that joins a 13u (or younger)
        team, you'll get a $100 credit towards dues.
      </p>
    </div>
  );
};

export default Fall;
