import { Helmet } from "react-helmet";

function Links() {
  return (
    <div className="content">
      <Helmet>
        <title>Useful Links</title>
        <meta
          name="description"
          content="SWVA is an organization under USA Volleyball.  Here are some links to USAV that might be useful."
        />
      </Helmet>
      <h1>Links</h1>
      <h2>
        <a
          href="http://www.azregionvolleyball.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Arizona Region
        </a>
      </h2>
      <p>
        Our club participates in the Arizona Region of USA Volleyball. This
        website contains all the information about playing in our region.
      </p>

      <h2>
        <a
          href="http://www.azregionvolleyball.org/page/show/3583115-tournament-info"
          target="_blank"
          rel="noopener noreferrer"
        >
          Tournament Schedule
        </a>
      </h2>
      <p>
        Tournament days can be hectic, use this link to find playing locations,
        schedules and more.
      </p>

      <h2>
        <a
          href="http://www.teamusa.org/USA-Volleyball"
          target="_blank"
          rel="noopener noreferrer"
        >
          USAV
        </a>
      </h2>
      <p>Our region is a part of USA Volleyball</p>
    </div>
  );
}
export default Links;
