import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import faqs from "../../components/img/heroimgs/faqs.JPG";
import hb from "../../components/docs/2023SeasonHandbook.pdf";

function FAQs() {
  return (
    <div className="content">
      <Helmet>
        <title>Sidewinder FAQs</title>
        <meta
          name="description"
          content="Frequently asked questions about Sidewinder Volleyball Academy and club volleyball in general."
        />
      </Helmet>
      <h1>FAQs</h1>
      <div style={{ textAlign: "center" }}>
        <div className="pageHero">
          <img src={faqs} />
        </div>
      </div>
      <h5>
        Many questions can be answered by reading the{" "}
        <a href={hb}>Club Handbook</a>
      </h5>
      <h3>Tryout FAQs</h3>
      <h5>When are tryouts for club volleyball?</h5>
      <p>
        Check out the <Link to="/tryouts">Tryout Page</Link> for dates and
        times. Plan to arrive at least 30 minutes early to check in and warm up.
      </p>
      <h5>How teams are formed</h5>
      <p>
        Our coaching staff will be guiding athletes through a 2 hour tryout
        utilizing different volleyball drills to evaluate skill and attitude to
        determine teams. Athletes are generally kept with other athletes of the
        same age although certain athletes may be asked to move "up" to an older
        age group.
      </p>
      <p>
        At the end of tryouts, if your athlete makes a team, you will be asked
        to turn in a commitment contract and pay the initial $500 in dues.
      </p>
      <h5>What is a commitment contract?</h5>
      <p>
        A commitment contract states that the athlete will play for Sidewinder
        Volleyball Academy (SWVA) for the duration of the season. Once you have
        signed a contract with one club, you may not switch to another club
        until the following season or until SWVA releases the athlete. SWVA is
        not obligated to release any athlete before the end of the volleyball
        season they have committed to.{" "}
      </p>
      <h5>What happens after tryouts?</h5>
      <p>
        Practices start in the weeks following tryouts. The first tournament is
        in January. The region club volleyball season ends late-May/early-June.
        "Travel teams" competing out of state and in the Volleyball National
        Championship Tournament play into mid-July.
      </p>
      <h3>Financial FAQs</h3>
      <h5>What do my dues pay for?</h5>
      <p>
        Court time, coach salaries, team gear, tournament entry fees, region
        fees, facility insurance, liability insurance, and other general club
        operating expenses.
      </p>
      <h5>What about non-payements and refunds?</h5>
      <p>
        Athletes with delinquent accounts will be asked to sit out of practices
        and tournaments until the account is in good standing. All dues are
        non-refundable.
      </p>
      <h5>What about food and transportation?</h5>
      <p>
        Athletes and parents are responsibile for food and transport on
        tournament days. Understand that many saturday region tournaments will
        be in Phoenix. We are lucky when we get to play volleyball in Tucson.
      </p>
      <h3>Other FAQs</h3>
      <h5>What experience do I need to play club?</h5>
      <p>
        None! For many athletes, club volleyball is the first steps into
        competitive volleyball. At the younger levels, we are typically looking
        at general athletic ability over actual volleyball skill. We have the
        coaching staff to develop an athlete starting at any skill level!
      </p>
      <h5>What if I have to miss all/part of tryouts?</h5>
      <p>
        Making part of tryouts is better than missing completely! Once tryouts
        happen, roster spots fill up with commitments from athletes who attended
        the tryout. If there are roster spots remaining, the directors will
        determine if a late tryout is possible for those who missed.
      </p>
      <h5>Do parents need to attend tryouts?</h5>
      <p>
        At the conclusion of tryouts, athletes who made a team will be asked to
        submit their committment contract. This contract must be signed by a
        parent. Parents can stay to sign the contract when the teams are formed
        or download the form, sign it, and send it to tryouts with their child.
      </p>
      <h5>What if we have time conflicts with other sports?</h5>
      <p>
        High School sports take priority and athletes will not be punished for
        missing to participate in school athletics. Keep in mind that athletes
        who continually miss practice will not improve at the same rate as their
        teammates who attend practice. Dues will not be adjusted.
      </p>
      <h5>How many athletes are on a team?</h5>
      <p>
        8-13 athletes compete on each team. Typically, a "travel team" will have
        fewer athletes than a region team.
      </p>
      <h5>How does playing time work?</h5>
      <p>
        Due to the nature of volleyball and the different positions, playng time
        between teammates will never be 100% equal. This is true for any
        volleyball team with more than 6 players (every competitive team). All
        athletes will get a chance to play and progress.
      </p>
    </div>
  );
}
export default FAQs;
