import "./Store.css";

import { Helmet } from "react-helmet";

function StoreSuccess() {
  return (
    <div>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className="content">
        <h2>Thank you!</h2>
        <h4>Your order has successfully been placed.</h4>
        <p>
          Thank you for supporting our athletes by buying team gear! Your order
          will be delivered to your athlete at their next practice! If you'd
          like your apparel shipped, please send us an email.
        </p>
      </div>
    </div>
  );
}

export default StoreSuccess;
