import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import girlsteam from "../../../components/img/heroimgs/girlsteam.jpg";

function Tryouts() {
  return (
    <div className="content">
      <Helmet>
        <title>Girl's Season</title>
        <meta
          name="description"
          content="All aboout our girl's youth volleyball club season in Tucson, Arizona."
        />
      </Helmet>
      <h1>2024 Girl's Season</h1>
      <div style={{ textAlign: "center" }}>
        <h3>
          <Link to="/tryoutprocess">
            Follow this guide to sign up and join us!
          </Link>
        </h3>
        <h3>Girls 10u (5th Grade) - 14u (8th grade) Dates:</h3>
        <ul style={{ listStyle: "none" }}>
          <li>Tryouts: Sunday November 11th, 6pm - 8:30pm at Sporting Chance Center</li>
        </ul>
        <h3>Girls 15u (Freshmen) - Girls 17u (Juniors) Dates:</h3>
        <ul style={{ listStyle: "none" }}>
          <li>Open Gym: Sunday November 17th, 3pm - 5pm at Tucson High School</li>
          <li>Open Gym: Sunday November 24th, 3pm - 5pm at Tucson High School</li>
          <li>Tryouts: Saturday November 30th, Time TBA at Tucson High School</li>
        </ul>
        <div className="pageHero">
          <img src={girlsteam} />
        </div>
      </div>
      {/* <div style={{ textAlign: "center" }}>
        <h3>U12-U14 (born on or after July 1, 2009):</h3>
        <ul style={{ listStyle: "none", padding: 0 }}>
          <li>October 27th: Open House from 7:00 PM to 9:00 PM</li>
          <li>November 4th: Open House from 6:15 PM to 8:15 PM</li>
          <li>
            <strong>November 5th: Tryouts from 4:00 PM to 6:00 PM</strong>
          </li>
        </ul>
        <a
          href="https://dashboard.sidewinderva.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Register Here
        </a>
        <h3>15u-17u (born before July 1, 2009)</h3>
        <ul style={{ listStyle: "none", padding: 0 }}>
          <li>
            <strong>MOVED!</strong> November 14th: Open House from 6:00 PM to
            8:00 PM <strong>AT ASDB</strong>
          </li>
          <li>
            Arizona State School for the Deaf and Blind. 1200 W Speedway Blvd.
          </li>
          <li></li>
          <li>November 18th: Open House from 6:15 PM to 8:15 PM</li>
          <li>
            <strong>November 25th: Tryouts from 6:30 PM to 8:30 PM</strong>
          </li>
        </ul>
        <a
          href="https://dashboard.sidewinderva.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Register Here
        </a>
      </div> */}
      <h1>FAQs</h1>
      <h3>What Age Should I Tryout For?</h3>
      <p>
        When you register your athlete for tryouts using the steps linked above,
        their "club age" is now listed directly on the registration portal.
      </p>
      <h3>How does the club season work?</h3>
      <p>
        Each Sidewinder team is made up of 9-13 kids from all around Tucson.
        Teams are broken up according to their age and their may be more than
        one team per age group. Usually one team in each age group will be a
        "travel" team and the rest will be "region" teams.
      </p>
      <p>
        All Sidewinder teams play in the Arizona Region of USA Volleyball.
        Arizona Region tournaments are half-day tournaments, typically on
        Saturday, where our teams are matched up against other club teams from
        around Arizona according to their skill level. Tournaments are typically
        hosted in Phoenix. Each team practices two times per week at night at
        one of our partner schools. We currently use Tucson High School and
        Arizona School for the Deaf and Blind for practices. Both are located
        off of Speedway and I-10.
      </p>
      <h3>What is a region team?</h3>
      <p>
        Region teams have 6 half day tournaments (starting at either 8am or 3pm)
        and 1 full day tournament. Region teams will also participate in the
        Cactus Classic over MLK weekend. Teams will start practicing in January
        and end with the Region Championship tournament in May.
      </p>
      <p>
        We do not travel as a team - each family is responsible for their own
        travel to and from tournaments. There are no multi-day out-of-town
        tournaments for region teams, so you will not need to worry about
        hotels.
      </p>
      <h3>What is a travel team?</h3>
      <p>
        Travel teams have all of the same tournaments as the region teams but
        also compete nationally. Every travel team will compete in the national
        championship tournament in June/July. Certain travel teams may also
        attend out-of-state "qualifier" tournaments to attempt to qualify for a
        higher division at the national tournament.
      </p>
      <p>
        We do not travel as a team - each family is responsible for their own
        travel and lodging. There are TWO multi-day out-of-town tournaments. The
        first tournament will require a 3-night hotel stay and Summer Nationals
        will require a 4-night hotel stay.
      </p>
      <h3>Total Season Cost</h3>
      <ul>
        <li>Tryouts are $25</li>
        <li>
          <i>All</i> athletes will need a USA Volleyball membership for $65
        </li>
        <li>
          <i>Travel team</i> athletes will need an AAU membership for $20
        </li>
      </ul>
      After making a team, dues will be structured as shown below. These dues
      cover everything our teams need for the season,{" "}
      <strong>there are no extra fees.</strong>
      <p>
        <strong>Region Teams</strong>
      </p>
      <p>
        Region teams attend 6 region tournaments in Phoenix against other clubs
        in the Arizona Region. Girl's region teams will also participate in the
        Cactus Classic (Tucson Convention Center) over MLK weekend against teams
        from around the country. The region team season ends with the region
        championships in late April/early May.
      </p>
      <table>
        <thead>
          <tr>
            <td>Due Date</td>
            <td>Monthly Plan</td>
            <td>Pay In Full Plan</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Directly After Tryouts</td>
            <td>$400</td>
            <td>$400</td>
          </tr>
          <tr>
            <td>Dec 5th, 2022</td>
            <td>$0</td>
            <td>$1450</td>
          </tr>
          <tr>
            <td>Jan 1st, 2023</td>
            <td>$400</td>
            <td>$0</td>
          </tr>
          <tr>
            <td>Feb 1st, 2023</td>
            <td>$400</td>
            <td>$0</td>
          </tr>
          <tr>
            <td>Mar 1st, 2023</td>
            <td>$400</td>
            <td>$0</td>
          </tr>
          <tr>
            <td>Apr 1st, 2023</td>
            <td>$400</td>
            <td>$0</td>
          </tr>
          <tr className="boldrow">
            <td>Total</td>
            <td>$2000</td>
            <td>$1850</td>
          </tr>
        </tbody>
      </table>
      <p>
        <strong>Travel Teams</strong>
        <p>
          In addition to the 6 region tournaments and Cactus Classic (Tucson,
          MLK Weekend), girl's travel teams also go to a national level travel
          tournament (April) and the AAU Girls Junior National Championship
          (June).
        </p>
      </p>
      <table>
        <thead>
          <tr>
            <td>Due Date</td>
            <td>Monthly Plan</td>
            <td>Pay In Full Plan</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Directly After Tryouts</td>
            <td>$500</td>
            <td>$500</td>
          </tr>
          <tr>
            <td>Dec 5th, 2022</td>
            <td>$0</td>
            <td>$2350</td>
          </tr>
          <tr>
            <td>Jan 1st, 2023</td>
            <td>$500</td>
            <td>$0</td>
          </tr>
          <tr>
            <td>Feb 1st, 2023</td>
            <td>$500</td>
            <td>$0</td>
          </tr>
          <tr>
            <td>Mar 1st, 2023</td>
            <td>$500</td>
            <td>$0</td>
          </tr>
          <tr>
            <td>Apr 1st, 2023</td>
            <td>$500</td>
            <td>$0</td>
          </tr>
          <tr>
            <td>May 1st, 2023</td>
            <td>$500</td>
            <td>$0</td>
          </tr>
          <tr className="boldrow">
            <td>Total</td>
            <td>$3000</td>
            <td>$2850</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
export default Tryouts;
