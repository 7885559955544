import { Link } from "react-router-dom";

function PageNotFound() {
    return (
      <div className="content">
        <h1>Out of Bounds!</h1>
        <h3>The page you were looking for seems to be missing.  Please contact us if you believe there is a website issue.</h3>
      </div>
    );
  }
  export default PageNotFound;