import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

function APVFAQs() {
  return (
    <div className="content">
      <Helmet>
        <title>APV Merger FAQs</title>
        <meta
          name="description"
          content="Frequently asked questions about Arizona Premier Volleyball merging with Sidewinder Volleyball Academy."
        />
      </Helmet>
      <h1>APV + Sidewinder Information</h1>
      <h3>A note from Nic Thiltges</h3>
      <p>
        Hello APV families!
        <br />
        <br />I hope everyone is having a good summer and enjoying their last
        little stretch before school starts again.  I am writing this note to
        let everyone associated with the club know that I will be stepping down
        from my position as the club director. I am hoping to stay involved in
        coaching the boys, but after 10 years as the director it is time for me
        to reduce my involvement. APV will be closing down as a club, but our
        coaching staff will largely continue working with the boys under Andrew
        Adair’s club, Sidewinder volleyball. With Andrew being from Tucson, an
        APV alum and having played at the U of A as well, we feel he will do a
        great job taking care of the volleyball community he has been a part of
        so long as a player. I personally look forward to the direction Andrew
        wants to go with volleyball and think it will continue to grow well
        under his guidance.  I have greatly enjoyed my time as the director of
        APV and feel lucky to have met so many great families and awesome young
        men.  Please feel free to reach out if you have any questions.
        <br />
        <br />
        As always, thank you for your time, <br />
        -Nic
      </p>
      <h3>What's staying the same?</h3>
      <p>
        We're excited to have the support of APV's staff fully behind us, making
        us as strong a club as we have ever been! All of our APV coaches are
        staying on, and we're adding a few new ones too! Our practices will
        still be at Tucson High and Palo Verde, twice a week.
      </p>
      <h3>What's changing?</h3>
      <p>
        We'll be adding in an extra skill-specific clinic each week, open to any
        Sidewinder players. These skill clinics will be run by our{" "}
        <a href="/staff/Wells">
          Co-Director and Head of Development Kyle Wells
        </a>{" "}
        and focus on the fundamentals of each skill (Serving, Passing,
        Attacking, etc.) These clinics ensure that ALL of our athletes, from our
        14s teams to our 18s team, practice the same good habits and learn the
        same fundamentals.
      </p>
      <p>
        Sidewinder is a new name! With that, the boys will be getting enough
        Sidewinder shirts to wear to a week's worth of practices and skill
        clinics as well as other gear like backpacks and new warmups. The
        athletes will be expected to wear their Sidewinder gear to each practice
        as we try and build a stronger team culture and sense of family within
        our club.
      </p>
      <p>
        Sidewinder is heavily focused on promoting our athletes and providing
        21st century resources for them! Check out{" "}
        <a
          href="https://www.instagram.com/sidewinder_va"
          target="_blank"
          rel="noopener noreferrer"
        >
          our Instagram
        </a>{" "}
        for examples of how we use social media as a platform to celebrate our
        athlete's achievements. Athletes can also follow us to get tips on
        different skills through our video reels of our coaches in clinics and
        practices. We also use{" "}
        <a
          href="https://stattogether.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Stat Together
        </a>{" "}
        to live stream our matches, giving players content to create highlight
        reels and other videos for their college recruitment.
      </p>
      <h3>How will tryouts work?</h3>
      <p>
        Our tryout format is staying the same as years previous. We'll still be
        hosting tryouts at Tucson High and we'll still be hosting seperate
        tryouts for our younger group of boys and our older group of boys. The
        only change being made is how you register! Everything is being
        streamlined through the use of an online portal.
      </p>
      <p>
        <strong>
          Parents should follow{" "}
          <a
            href="https://dashboard.SidewinderVA.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            this link
          </a>{" "}
          to make a Sidewinder account and register their athlete for tryouts on
          August 12.
        </strong>
      </p>
    </div>
  );
}
export default APVFAQs;
