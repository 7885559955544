import "./Store.css";

import { Helmet } from "react-helmet";

function StoreCancel() {
  return (
    <div>
      <div className="content">
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
        <h2>Oops!</h2>
        <p>
          Something went wrong and the order was cancelled. You can close this
          tab, your shopping cart should still be available in another tab.
        </p>
      </div>
    </div>
  );
}

export default StoreCancel;
