import React from "react";

const Summer = () => {
  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <h1>2024 Summer is complete! See you next year!</h1>
      </div>
      <h3>What does the Summer season look like?</h3>
      <h5>Late May - End of June</h5>
      <div>
        <p style={{ textAlign: "left" }}>
          3 practices/week. Travel teams compete in 2 national-level
          tournaments. Depending on the situation, athletes who cannot travel to
          both tournaments may still travel to 1 tournament or may be asked to
          practice with a practice team. Practice teams do not travel to
          tournaments, but still practice alongside travel teams. The cost
          difference between the two teams are at the bottom of this page.
        </p>
        <p style={{ textAlign: "left" }}>
          There is a new tryout for Summer season - rosters from the previous
          fall season do not stay the same into summer season.
        </p>
      </div>
      <h3>Projected 2024 Team and Coaches</h3>
      <h5>
        These are{" "}
        <em>
          <u>projections</u>
        </em>{" "}
        based on expected athlete turnout. More or less teams may be made at
        tryouts based on actual athlete turnout.
      </h5>
      <ul>
        <li>
          <strong>18-1</strong> - Heather Mott, Dave Rubio, Carson Mott
        </li>
        <li>
          <strong>18-2</strong> - Stephen Huff, Aiden Van Hoesen, Matt Burke
        </li>
        <br />
        <li>
          <strong>17-1</strong> - Keifer Neerhof, Brett Marx, Tristan Emma
        </li>
        <br />
        <li>
          <strong>16-1</strong> - Brianna Denton, Zach Denton, Kyle Boesen
        </li>
        <li>
          <strong>16-2</strong> - Jules Serna, Cheyenne Smith, Ayda Felix
        </li>
        <br />
        <li>
          <strong>15-1</strong> - Charlie de la Vara, Lorenzo Rocha, Aiden Keen
        </li>
        <li>
          <strong>15-2</strong> - Kyle Wells, Jane DeVries, Franklin Donley, Sam
          Gillman
        </li>
        <br />
        <li>
          <strong>14-1</strong> - Laurent Riel, Aurora Molina, Rueben Bautista
        </li>
      </ul>
      <h3>Tournaments Attended</h3>
      <ul style={{ listStyle: "none" }}>
        <li>
          June 14th - 16th: SoCal Showcase (Los Angeles Convention Center)
        </li>
        <li>
          June 26th - 29th: USA Volleyball National Championships (Kay Bailey
          Hutchison Convention Center, Dallas)*
        </li>
      </ul>
      <p>
        * Our 2024 18-1 and 15-1 teams will be attending AAU Nationals in
        Orlando instead of USAV Nationals in Dallas.
        <br />
        18-1: June 30th - July 3rd
        <br />
        15-1: July 4th - 7th
      </p>
      <h3>Costs</h3>
      <p>
        Tryouts are $25. After making a team, dues will be structured as shown
        below:
      </p>
      <h4>Summer Practice Teams</h4>
      <p>
        Practice teams do not travel to any tournaments but still practice as a
        team for 5 weeks. (May 20th - June 21st). Please tell us at tryouts if
        you are interested in a practice team instead of traveling.
      </p>
      <table>
        <thead>
          <tr>
            <td>Due Date</td>
            <td>Amount</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Directly After Tryouts (May 18th)</td>
            <td>$200</td>
          </tr>
          <tr>
            <td>Jun 1st</td>
            <td>$200</td>
          </tr>
          <tr className="boldrow">
            <td>Total</td>
            <td>$400</td>
          </tr>
        </tbody>
      </table>
      <h4>Summer Travel Teams</h4>
      <table>
        <thead>
          <tr>
            <td>Due Date</td>
            <td>Amount</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Directly After Tryouts (May 18th)</td>
            <td>$500</td>
          </tr>
          <tr>
            <td>Jun 1st</td>
            <td>$550</td>
          </tr>
          <tr className="boldrow">
            <td>Total</td>
            <td>$1050</td>
          </tr>
        </tbody>
      </table>
      <p>
        Travel/hotels are not covered with dues for travel teams. Travel team
        parents should also budget for travel/hotel expenses for both
        tournaments.
      </p>
    </div>
  );
};

export default Summer;
