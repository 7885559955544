import { Helmet } from "react-helmet";
import med from "../../components/docs/MedicalRelease.pdf";
function TryoutProcess() {
  return (
    <div className="content">
      <Helmet>
        <title>Tryout Process</title>
        <meta
          name="description"
          content="New to club volleyball tryouts? Find out how to get registered here."
        />
      </Helmet>
      <h1>Club Team Tryout Process</h1>
      <h5>These steps must be completed in order:</h5>
      <h3>Step 1: Register with Sidewinder</h3>
      <p>
        You must register for tryouts using our{" "}
        <a
          href="https://dashboard.sidewinderva.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          online portal.
        </a>
      </p>
      <h3>Step 2: Join the region</h3>
      <p>
        All athletes must be become members of the Arizona Region in order to
        participate in club volleyball.
        <br />
        <br /> <u>NOTE: If you are signing up for boy's tryouts in August</u>,
        please wait until September 1st to purchase a membership. Memberships
        are valid from September 1st - August 31st.
      </p>

      <ol>
        <li>
          Use{" "}
          <a
            href="http://user.sportngin.com/users/sign_in"
            target="_blank"
            rel="noopener noreferrer"
          >
            this link
          </a>{" "}
          to sign in or create a sportsengine account.
        </li>
        <li>
          After signing in, click{" "}
          <a
            href="https://memberships.sportsengine.com/org/arizona-region-of-usa-volleyball/who"
            target="_blank"
            rel="noopener noreferrer"
          >
            this link
          </a>{" "}
          to join the AZ Region. The process is complete when you get to "Add a
          child" and purchase a <u>junior</u> membership. <br />
          <br />
          <strong>
            <u>
              The PARENT does not have to pay for their membership - only the
              junior membership for the athlete.
            </u>
          </strong>{" "}
          <br />
        </li>
        <li>
          Complete Eligibility Requirements as seen in{" "}
          <a
            href="https://fast.wistia.net/embed/channel/05cphm3nsr?wchannelid=05cphm3nsr&wmediaid=gjqjpm2jpq"
            target="_blank"
            rel="noopener noreferrer"
          >
            this video.
          </a>
        </li>
        <li>
          Learn how to access your digital membership in{" "}
          <a
            href="https://fast.wistia.net/embed/channel/05cphm3nsr?wchannelid=05cphm3nsr&wmediaid=gjqjpm2jpq"
            target="_blank"
            rel="noopener noreferrer"
          >
            this video.
          </a>
        </li>
      </ol>
      <h3>Step 3: Bring Paperwork:</h3>
      <ul>
        <li>
          Copy of athlete's birth certificate IF first time playing club in the
          Arizona region
        </li>
        <li>
          <a href={med} download>
            Medical Release (print hard copy and bring to tryouts)
          </a>
        </li>
        <li>$25 Tryout Fee</li>
      </ul>
    </div>
  );
}
export default TryoutProcess;
