function SafeSport() {
  return (
    <div className="content">
      <h1>SafeSport Program</h1>
      <p>
        Sidewinder, The Arizona Region, and USAV hava ZERO TOLERANCE for abuse
        and misconduct.
      </p>
      <p>
        Visit the{" "}
        <a
          href="https://www.azregionvolleyball.org/safesport"
          target="_blank"
          rel="noopener noreferrer"
        >
          Arizona Region's SafeSport page
        </a>{" "}
        to get more information and resources about SafeSport.
      </p>
      <p>
        Andrew Adair is Sidewinder's SafeSport contact. Please contact him if
        you need to report any abuse. You can also report abuse to the Arizona
        Region or via the REPORT ABUSE link on the USA Volleyball page.
      </p>
    </div>
  );
}
export default SafeSport;
