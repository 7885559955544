export const Dropdown = ({ label, value, options, onChange, hideLabel }) => {
  return (
    <div className="dropdown">
      {hideLabel ? null : <label>{label}</label>}
      <div className="select">
        <select value={value} onChange={onChange}>
          {options.map((option, index) => (
            <option value={option.value} key={`${index} ${option.value}`}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};
