import { BsDashCircleFill, BsPlusCircleFill } from "react-icons/bs";
import React, { useState } from "react";

const ProductOverlay = ({
  product,
  maximumSizes,
  setSelectedProduct,
  addItemsToCart,
  tossRef,
}) => {
  const [sizes, setSizes] = useState(
    maximumSizes.map((size) => {
      return { size: size.size, quantity: 0 };
    })
  );

  const changeSizes = ({ size, change }) => {
    const prevQuantity = sizes.find(
      (thisSize) => thisSize.size === size.size
    ).quantity;
    if (
      prevQuantity + change < 0 ||
      prevQuantity + change >
        maximumSizes.find((thisSize) => thisSize.size === size.size).quantity
    ) {
      return;
    }
    const newItem = [...sizes];

    newItem.find((thisSize) => thisSize.size === size.size).quantity += change;

    setSizes(newItem);
  };

  const addSizesToCart = () => {
    addItemsToCart({ product: product, sizes: sizes });
    setSelectedProduct(null);
    console.log(sizes);
    if (sizes.reduce((s, a) => s + a.quantity, 0) > 0) {
      tossToCart();
    }
  };

  const sizeVal = { XS: 0, S: 1, M: 2, L: 3, XL: 4, "2XL": 5 };

  const tossToCart = () => {
    tossRef.current.classList.add("send-to-cart");
    setTimeout(() => {
      tossRef.current.classList.remove("send-to-cart");
    }, 1000);
  };

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(0,0,0,0.8)",
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 99999,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          backgroundColor: "rgb(0,0,0)",
          padding: "32px",
          borderRadius: "8px",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          textAlign: "left",
        }}
        className="prevent-select"
      >
        <h1>{product.name}</h1>
        <img
          src={product.images[0]}
          alt={product.name}
          style={{
            maxWidth: "80vw",
            maxHeight: "30vh",
            aspectRatio: "3 / 2",
            borderRadius: "8px",
          }}
        />

        {sizes
          .sort((a, b) => sizeVal[a.size] - sizeVal[b.size])
          .map((size) => {
            const maxAvailable = maximumSizes.find(
              (thisSize) => thisSize.size === size.size
            ).quantity;
            const amtSelected = sizes.find(
              (thisSize) => thisSize.size === size.size
            ).quantity;
            return (
              <div className="sizeLine">
                {maxAvailable === 0 ? (
                  <span>No {size.size}s in stock</span>
                ) : (
                  <>
                    <BsDashCircleFill
                      onClick={() => changeSizes({ size, change: -1 })}
                      style={{
                        cursor: "pointer",
                        opacity: amtSelected === 0 ? 0 : 1,
                      }}
                    />
                    <h5>
                      {size.size}: {size.quantity}
                    </h5>
                    <BsPlusCircleFill
                      onClick={() => changeSizes({ size, change: 1 })}
                      style={{
                        cursor: "pointer",
                        opacity: amtSelected === maxAvailable ? 0 : 1,
                      }}
                    />
                  </>
                )}
              </div>
            );
          })}
        {sizes.length > 0 ? (
          <button style={{ marginTop: 16 }} onClick={() => addSizesToCart()}>
            Add to Cart
          </button>
        ) : (
          <h4>Sorry! We're out of stock!</h4>
        )}
        <button
          style={{ marginTop: 32 }}
          onClick={() => setSelectedProduct(null)}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default ProductOverlay;
