import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import tournament from "../../components/img/heroimgs/tournament.png";

function Tournaments() {
  return (
    <div className="content">
      <Helmet>
        <title>Practices</title>
        <meta
          name="description"
          content="New to volleyball tournaments?  Get advice on what to bring and where to be."
        />
      </Helmet>
      <h1>Tournaments</h1>
      <div style={{ textAlign: "center" }}>
        <div className="pageHero">
          <img src={tournament} />
        </div>
      </div>
      <h5>Plan ahead!</h5>
      <p>
        All tournament dates are posted at the beginning of the season. Boys
        play throughout the morning and into the afternoon, girls play in either
        an AM or PM wave. Please plan to reserve the entire saturday for
        competition - we will not know if we are AM or PM wave until the
        Wednesday prior to each tournament. Refunds are not granted for any
        missed competitions.
      </p>
      <h5>Locations</h5>
      <p>
        The{" "}
        <a
          href="https://www.azregionvolleyball.org/page/show/3583115-tournament-info"
          target="_blank"
          rel="noopener noreferrer"
        >
          Arizona Region Website
        </a>{" "}
        has all tournament information including playing sites and driving
        directions. We will be in Phoenix for a majority of tournaments.
        Locations are given to us by the Arizona Region the Wednesday before a
        competition.
      </p>
      <h5>Timing is everything!</h5>
      <p>
        All athletes must be at the playing site a minimum 45 minutes prior to
        competition. Even if your team is not scheduled to ref or play the first
        match, Arizona Region rules state that players must be present for
        check-in. Last minute schedule changes do happen - you might be playing
        earlier than expected!
      </p>
      <h5>Site Rules</h5>
      <p>
        Everything our parents and players do while in Sidewinder gear is a
        reflection of our club. We expect all members and spectators to follow
        all posted rules at playing facilities. EVERY facility has at least one
        rule: no food or drinks in the gym. After a tournament, make sure you
        pack out anything you packed in - don't leave any trash behind.
      </p>
      <h5>Food and Drink</h5>
      <p>
        Tournaments are a day-long event - typically over 6 hours. Bring WATER
        and healthy snacks. There is no scheduled lunch break but athletes can
        grab a quick bite to eat when their team is "off" and other teams are
        scheduled to play on their court instead. Many facilities allow a "team
        camp" setup - come early if you would like to establish a spot for the
        team to hangout outside between matches. Remember that no food or drink
        is allowed in the gym so a team camp is usually the best option.
      </p>
      <h5>Uniforms</h5>
      <p>
        When playing in Phoenix, you don't want to forget anything! Checklist:
      </p>
      <ul>
        <li>Shoes</li>
        <li>Socks</li>
        <li>Kneepads</li>
        <li>Both Uniforms*</li>
        <li>Hair Ties</li>
        <li>Personal Hygiene Products</li>
        <li>Food and Water</li>
      </ul>
      <p>
        *Sometimes teams have to change uniforms. Your athlete might make an
        appearance as a libero. Bring both uniforms just in case!
      </p>
      <h5>Parent Gear</h5>
      <p>
        Sidewinder offers <Link to="/apparel">apparel</Link> for parents! In
        addition to supporting your favorite athlete with Sidewinder gear, don't
        forget:
      </p>
      <ul>
        <li>
          Chairs. Sometimes there isn't enough seating inside and you will want
          a chair for the team camp outside! It's also a good idea to bring a
          towel to put under the chair to prevent damage to playing surfaces.
        </li>
        <li>
          The Right Clothes. Facilities don't always have the best
          heating/cooling. In can be cold inside during the winter (bring a
          blanket!) and warm in the summer. Dress comfortably - it's a long day!
        </li>
        <li>
          A Great Attitude. Let the players play, the coaches coach and the refs
          ref. You're there to be an awesome supporter of your athlete and their
          team! Set an example for how you want your athlete to be treated and
          how you want your athlete to treat others.
        </li>
      </ul>
    </div>
  );
}
export default Tournaments;
