import "./header.css";

import { Link, useLocation } from "react-router-dom";

import { SWLink } from "../components";
import logo from "../../components/img/header-logo.webp";

function Header() {
  const location = useLocation();
  const { pathname } = location;
  return (
    <div
      className="header"
      style={
        pathname === "/store" || pathname === "/apparel"
          ? { paddingTop: "34px" }
          : {}
      }
    >
      <div className="imgcontainer">
        <Link to="/">
          <img
            src={logo}
            className="logo"
            alt="Sidewinder Volleyball Academy Website Header"
          />
        </Link>
      </div>
      <nav className="navbar">
        <ul>
          <li className="home">
            <a href="/">Home</a>
          </li>
          <li className="dropdown">
            <a href="#" style={{ pointerEvents: "none" }}>
              About Us
            </a>
            <ul>
              <li>
                <SWLink to="/about" content="About Us" />
              </li>
              <li>
                <SWLink to="/contact" content="Contact" />
              </li>
              <li>
                <SWLink to="/staff" content="Staff" />
              </li>
              <li>
                <SWLink to="/teams" content="Teams" />
              </li>
            </ul>
          </li>
          <li className="dropdown">
            <a href="#" style={{ pointerEvents: "none" }}>
              Play
            </a>
            <ul>
              <li>
                <SWLink to="/tryouts" content="Girl's Season" />
              </li>
              <li>
                <SWLink to="/boystryouts" content="Boy's Season" />
              </li>
              <li>
                <SWLink to="/tryoutprocess" content="Tryout Process" />
              </li>
              <li>
                <SWLink to="/summerclinics" content="Summer Clinics" />
              </li>
            </ul>
          </li>
          <li className="dropdown">
            <a href="#" style={{ pointerEvents: "none" }}>
              Helpful Info
            </a>
            <ul>
              <li>
                <SWLink to="/faqs" content="FAQs" />
              </li>
              <li>
                <SWLink to="/practices" content="Practices" />
              </li>
              <li>
                <SWLink to="/tournaments" content="Tournaments" />
              </li>
              <li>
                <SWLink to="/safesport" content="Safe Sport" />
              </li>
              <li>
                <SWLink to="/links" content="USAV Links" />
              </li>
            </ul>
          </li>
          <li className="dropdown">
            <a href="/apparel">Apparel</a>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default Header;
