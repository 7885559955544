import "./components.css";

import { Link } from "react-router-dom";
import { useEffect } from "react";

function SWButton({ onClick, content }) {
  return (
    <div className="btnContainer">
      <button onClick={onClick}>{content}</button>
    </div>
  );
}

function CaptionImage(props) {
  return (
    <div className="captionImage">
      <img src={props.src} style={{ width: "auto" }} />
      <p>{props.caption}</p>
    </div>
  );
}

const SWLink = ({ className, to, content, style }) => {
  return (
    <Link to={to} className={className} onClick={() => {}} style={style}>
      {content}
    </Link>
  );
};

export { CaptionImage, SWButton, SWLink };
