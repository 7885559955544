import { Helmet } from "react-helmet";
import practice from "../../components/img/heroimgs/practice.JPG";

function Practices() {
  return (
    <div className="content">
      <Helmet>
        <title>Practices</title>
        <meta
          name="description"
          content="What we expect at a Sidewinder volleyball practice."
        />
      </Helmet>
      <h1>Practices</h1>
      <div style={{ textAlign: "center" }}>
        <div className="pageHero">
          <img src={practice} />
        </div>
      </div>
      <h5>Drop-Off and Pick-Up</h5>
      <p>
        Practice will start on time and end on time. Make every effort to arrive
        15 minutes before practice so your athlete can help with equiptment
        set-up. Every athlete is expected to help out. We will have your
        athletes ready to go at the scheduled practice end time. Coaches may not
        leave until all athletes have transportation - please be courteous by
        being on time!
      </p>
      <h5>Spectators</h5>
      <p>
        Parents are welcome to come and observe practices.{" "}
        <strong>
          Only USAV members will be allowed on the court to play or assist.
        </strong>{" "}
        Sibilings may not play in the gym area during practice. Parents hoping
        to assist must register as a USAV member first ($55 anually + $14
        background check fee). Please contact us if this is something you are
        interested in.
      </p>
      <h5>Structure</h5>
      <p>
        Practices take place 2 to 3 times a week and are typically 2 hours in
        length. Throughout the year, coaches may also reach out to invite
        athletes to "positional" practices with other athletes in the club who
        play the same position.
      </p>
      <p>
        2-3 coaches are present at every practice to ensure each athlete is
        getting spcific training feedback. Head coaches arrive with a practice
        plan that they feel will best progress their team and assistant coaches
        help execute that plan by running drills and providing feedback to the
        players.
      </p>
    </div>
  );
}
export default Practices;
