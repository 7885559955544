import "./contact.css";

import { Helmet } from "react-helmet";
import andrew from "../../components/img/coaches/head-andrew.JPG";
import kyle from "../../components/img/coaches/head-kyle.JPG";

function Contact() {
  const ContactCard = ({ name, role, contactIf, email, phone, img }) => {
    return (
      <div className="contactCard">
        <img src={img} style={{ width: 100, borderRadius: 50 }} alt="Andrew" />
        <div>
          <h3>{name}</h3>
          <h6>Director, {role}</h6>
        </div>
        <p>
          <strong>Contact if:</strong> {contactIf}
        </p>
        <p>
          <strong>Email:</strong> <a href={`mailto:${email}`}>{email}</a>
          <br />
          <strong>Phone:</strong> {phone}
        </p>
      </div>
    );
  };

  return (
    <div className="content">
      <Helmet>
        <title>About Us</title>
        <meta
          name="description"
          content="How to contact Sidewinder Volleyball Academy - a youth volleyball club servicing the
        Tucson, AZ area."
        />
      </Helmet>
      <h1>Contact</h1>
      <br />
      <div className="contactContainer">
        <ContactCard
          name="Andrew Adair"
          role="Administration"
          contactIf="You have questions about tryouts, scheduling, payments or anything else behind the scenes."
          img={andrew}
          email="director@sidewinderva.com"
          phone="(520) 850-3467"
        />
        <ContactCard
          name="Kyle Wells"
          role="Head of Development"
          contactIf="You have questions about players or coaches.  This includes questions about private lessons, practices, or summer clinics."
          img={kyle}
          email="director@sidewinderva.com"
          phone="(847) 715-8668"
        />
      </div>
      <h4 style={{ textAlign: "left" }}>
        Before contacting us with a team related issue, you must try to resolve
        the issue using these steps in order:
      </h4>
      <ol>
        <li>
          Athlete has a conversation with the coach. This helps your athlete
          build real-world skills in communication and problem solving.
        </li>
        <li>
          Athlete and parent contact coach together. This only occurs if the
          athlete and coach could not resolve the issue on their own.
        </li>
        <li>
          Athlete and Parent contact a director (Andrew Adair or Kyle Wells) to
          set up a meeting where the athlete, parent, coach AND a director are
          all present. <em>Team issues should rarely reach this step.</em>
        </li>
      </ol>
      <h4>Most issues can usually be solved with step 1</h4>
      <h3 style={{ textAlign: "left" }}>
        If there is a SafeSport concern, contact a director (Andrew Adair or
        Kyle Wells) directly.
      </h3>
    </div>
  );
}
export default Contact;
