const axios = require("axios");

const testing = false;

export async function getAllProducts() {
  try {
    const response = await axios.get(
      "https://us-central1-sidewinder-3daf9.cloudfunctions.net/app/stripeproducts",
      {
        headers: {},
      }
    );
    return response;
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function createCheckout({ cancelUrl, successUrl, lineItems }) {
  try {
    const response = await axios.post(
      `https://us-central1-sidewinder-3daf9.cloudfunctions.net/app/createcheckout?cancelUrl=${cancelUrl}&successUrl=${successUrl}`,
      lineItems,
      {
        headers: {},
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getPrice(productid) {
  if (productid == null) {
    return [];
  }
  try {
    const response = await axios.get(
      `https://us-central1-sidewinder-3daf9.cloudfunctions.net/app/getprice?pid=${productid}`,
      {
        headers: {},
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getAllStock(result) {
  try {
    const response = await axios.get(
      `https://us-central1-sidewinder-3daf9.cloudfunctions.net/app/shopstock`,
      {
        headers: {},
      }
    );
    const allStock = response.data;
    for (let i = 0; i < result.length; i++) {
      result[i].stock =
        allStock[
          result[i].size
            ? `${result[i].size}-${result[i].name}`
            : result[i].name
        ];
    }
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
}
